
import Vue from "vue";
import Map from '@/components/Map/SetLocation.vue'

export default Vue.extend({
  props: {
    hideCancelButton: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  components: {
    Map,
  },

});
